import {
  ADD_CART_DATA,
  INCREMENT_CART_ITEM,
  SET_CART_DATA,
  SET_WISHLIST,
} from "../types";

const InitailState = {};

const cartReducer = (state = InitailState, action) => {
  switch (action.type) {
    case SET_CART_DATA:
      return { ...state, ...action.data };
    case SET_WISHLIST:
      return { ...state, wishLists: action.data };
    default:
      return state;
  }
};

export default cartReducer;
