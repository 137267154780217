import { combineReducers } from "redux";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import indexReducer from "./indexReducer";
import paymentReducer from "./paymentReducer";
import { prodReducer } from "./prodReducer";
import searchReducer from "./searchReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  payment: paymentReducer,
  prod: prodReducer,
  cart: cartReducer,
  search: searchReducer,
  index: indexReducer,
});

export default rootReducer;
