// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Sentry.init({
//   dsn: SENTRY_DSN || 'https://9ea52ceb65654c3c862bd53d805076b1@o1217369.ingest.sentry.io/6359419',
//   // Adjust this value in production, or use tracesSampler for greater control
//   tracesSampleRate: 1.0,
//   // ...
//   // Note: if you want to override the automatic release value, do not set a
//   // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//   // that it will also get attached to your source maps
// });

Sentry.init({
  dsn: "https://9ea52ceb65654c3c862bd53d805076b1@o1217369.ingest.sentry.io/6359419",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
