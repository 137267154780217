import {
  SET_SEARCH_RESULT,
  SET_RESULT_CONTROLLER,
  SET_SEARCH_LOADING,
  SET_SEARCH_FILTER,
  SET_SEARCH_MODE,
  SET_SEARCH_KEYWORD,
} from "../types";

const initialState = {
  items: [],
  loading: false,
  hasmore: true,
  page: 1,
  sort: "",
  type: "",
  title: "",
  category: null,
  count: null,
  user: null,
  mode: "product",
  filters: {},
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULT:
      return {
        ...state,
        ...action.data,
      };
    case SET_SEARCH_LOADING:
      return { ...state, loading: action.data };
    case SET_SEARCH_FILTER:
      console.log(action.data);
      return { ...state, ...action.data };
    // case SET_SEARCH_MODE: return { ...state, items: [], page: 1, ...action.data };
    default:
      return state || initialState;
  }
};

export default searchReducer;
