import {
  ADDRESS_STAGE,
  FETCHING_STAGE,
  PAYMENT_STAGE,
  SET_ADDRESS,
  SET_HOME_DATA,
  SET_PAYMENT_DATA,
  SET_STAGE,
} from "../types";

const initData = {
  loading: true,
};

const paymentReducer = (state = initData, action) => {
  const type = action.type;
  console.log(state);
  switch (type) {
    case SET_ADDRESS:
      return {
        ...state,
        address: action.data,
        askAddress: action.data === null,
      };
    case SET_PAYMENT_DATA:
      return {
        ...action.data,
        loading: false,
      };
    case SET_STAGE:
      return { ...state, state: action.data };
    default:
      return state;
  }
};

const NextStage = ({ address, items }) => {
  if (address) return PAYMENT_STAGE;
  if (
    items.find((x) => (x.priceType === "Delivery") | (x.type === "Tool_Rental"))
  )
    return ADDRESS_STAGE;
  else return PAYMENT_STAGE;
};

export default paymentReducer;
