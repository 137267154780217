
const InitialState = {
    product: null,
    id: null
}

export const prodReducer = (state = InitialState, action) => {
    switch (action.type) {

        case 'setproduct': return { ...state,product: action.data }
        case 'setprodid': return { ...state, id: action.data }
        default: return state;
    }

}