import { SET_AUTH_DATA, SET_INDEX_DATA, SET_ZIP_DATA } from "../types";
const initialState = {
  zipData: {
    zipcode: 21043,
    city: "Ellicott City",
    state: "Maryland",
    stateCode: "MD",
    county: "Howard",
    latitude: 39.2548,
    longitude: -76.8001,
  },
  banner: [],
  ads: [],
};

const indexReducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_INDEX_DATA:
      return { ...state, ...action.data };
    case SET_ZIP_DATA:
      return { ...state, zipData: data };

    // case SET_AUTH_DATA:
    //   return { ...state, authData: action.data };
    default:
      return state;
  }
};

export default indexReducer;
