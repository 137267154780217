import {
  AUTHENTICATE,
  DEAUTHENTICATE,
  SETACCOUNT,
  SET_AUTH_DATA,
  USER,
} from "../types";

const initialState = {
  authenticated: false,
  role: "",
  userid: "",
  name: "",
  username: "",
  email: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return action.data || initialState;
    default:
      return state;
  }
};
